import { Howl } from 'howler';
import Konva from 'konva';
import { Group as GroupClass } from 'konva/lib/Group';
import { Layer as LayerClass } from 'konva/lib/Layer';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { Text as TextShape } from 'konva/lib/shapes/Text';
import { Wedge as WedgeShape } from 'konva/lib/shapes/Wedge';
import { Stage as StageClass } from 'konva/lib/Stage';
import { Easings, Tween } from 'konva/lib/Tween';
import React, { useCallback, useEffect } from 'react';
import { Circle, Group, Layer, Stage, Text, Wedge } from 'react-konva';

import {
  ClaimedSpinPrize,
  UnclaimedSpin,
  WheelSpinPrizeOption,
} from '../../../api/interfaces/wheel-spins';
import { spinWheel } from '../../../api/wheel-spins';
import { isApiError } from '../../../utils/api-util';
import { rgbToHSL } from '../../../utils/color-util';
import { wait } from '../../../utils/misc-util';

// function cubicBezier(p1x: number, p1y: number, p2x: number, p2y: number) {
// function cubicBezier(p1y: number, p2y: number) {
//   // 't' is the current time, 'b' is the beginning value, 'c' is the change in value, and 'd' is the duration
//   return function (t: number, b: number, c: number, d: number) {
//     function cubic(p0: number, p1: number, p2: number, p3: number, t: number) {
//       return (
//         Math.pow(1 - t, 3) * p0 +
//         3 * Math.pow(1 - t, 2) * t * p1 +
//         3 * (1 - t) * Math.pow(t, 2) * p2 +
//         Math.pow(t, 3) * p3
//       );
//     }

//     // Solve for x(t)
//     // const x = cubic(0, p1x, p2x, 1, t / d);

//     // Solve for y(t)
//     const y = cubic(0, p1y, p2y, 1, t / d);

//     // Return scaled value based on c and b
//     return b + c * y;
//   };
// }

const findNewPoint = (x: number, y: number, angle: number, distance: number) => {
  return {
    x: Math.round(Math.cos((angle * Math.PI) / 180) * distance + x),
    y: Math.round(Math.sin((angle * Math.PI) / 180) * distance + y),
  };
};

const sliceSize = (total: number, radius: number) => {
  return findNewPoint(0, 0, 360 / total, radius);
};

interface WheelWedgeProps {
  reward: WheelSpinPrizeOption;
  index: number;
  total: number;
  radius: number;
  sliceWidth: number;
}

const WheelWedge: React.FC<WheelWedgeProps> = ({ reward, index, total, radius, sliceWidth }) => {
  const wedgeRef = React.useRef<GroupClass>(null);
  const wedgeBackgroundRef = React.useRef<WedgeShape>(null);
  const textRef = React.useRef<TextShape>(null);
  const rewardLabel = reward.title.split('–').join('–\n').split(' (').join('\n(');

  // Start colors
  const hexColor = reward.color.replace('#', '');
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const endColor = 'rgb(' + r + ',' + g + ',' + b + ')';
  const startColor = `rgb(${Math.min(r + 100, 255)},${Math.min(g + 100, 255)},${Math.min(b + 100, 255)})`;

  // convert rgb to hsl for text color determination
  const hsl = rgbToHSL(r, g, b);

  let textColor = 'rgb(255, 255, 255)';

  if (hsl.l > 50) {
    textColor = 'rgb(9, 28, 230)';
  }
  // End colors

  const angle = (2 * Math.PI) / total;
  const fontName = 'Brown';

  const lines = rewardLabel.split('\n').length;

  const fontSize = Math.round(radius / 17);
  const strokeWidth = Math.round(radius / 75);
  const textX = radius - Math.round(radius / 10);
  let textY = sliceWidth + (lines - 1) * fontSize;

  if (lines === 1) {
    textY = sliceWidth + fontSize / 3;
  }

  const rotation = (2 * index * Math.PI) / total;
  const textRotation = Math.PI + angle / 2;

  useEffect(() => {
    if (textRef.current) {
      textRef.current.cache();
    }

    if (wedgeRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      wedgeRef.current.startRotation = wedgeRef.current.rotation();
    }
  }, []);

  return (
    // wedge
    <Group ref={wedgeRef} rotation={rotation} id={String(index)}>
      <Wedge
        ref={wedgeBackgroundRef}
        radius={radius}
        angle={angle}
        fillRadialGradientStartPoint={{ x: 0, y: 0 }}
        fillRadialGradientStartRadius={0}
        fillRadialGradientEndPoint={{ x: 0, y: 0 }}
        fillRadialGradientEndRadius={radius}
        fillRadialGradientColorStops={[0, startColor, 1, endColor]}
        fill={'#64e9f8'}
        fillPriority={'radial-gradient'}
        stroke={'#fff'}
        strokeWidth={strokeWidth}
      />
      <Text
        ref={textRef}
        text={rewardLabel /* + `\nv: ${hsv.v}, l: ${hsl.l}`*/}
        fontFamily={fontName}
        fontSize={fontSize}
        fill={textColor}
        align={'center'}
        stroke={textColor}
        strokeWidth={0.5}
        rotation={textRotation}
        x={textX}
        y={textY}
        listening={false}
      />
    </Group>
  );
};

interface WheelOfFortuneProps {
  width: number;
  height: number;
  rewards: WheelSpinPrizeOption[];
  unclaimedSpins: UnclaimedSpin[] | undefined;
  onSpinningFinished: (spinId: number, result: ClaimedSpinPrize) => void;
}

const WheelOfFortune: React.FC<WheelOfFortuneProps> = ({
  width,
  height,
  rewards,
  unclaimedSpins,
  onSpinningFinished,
}) => {
  const stageRef = React.useRef<StageClass>(null);
  const layerRef = React.useRef<LayerClass>(null);
  const wheelRef = React.useRef<GroupClass>(null);
  const pointerRef = React.useRef<WedgeShape>(null);

  const [spinning, setSpinning] = React.useState(false);

  Konva.angleDeg = false;

  const PI = Math.PI;
  const TAU = 2 * PI;
  const arc = TAU / rewards.length;
  const angOffset = TAU * 0.75; // needle is north

  // let ang = 0; // Angle rotation in radians
  const [ang, setAng] = React.useState(0);

  /**
   * Generate random float in range min-max
   */
  const rand = (min: number, max: number) => Math.random() * (max - min) + min;

  /**
   * Fix negative modulo
   * https://stackoverflow.com/a/71167019/383904
   */
  const mod = (n: number, m: number) => ((n % m) + m) % m;

  const handleClick = useCallback(async () => {
    if (spinning) return;
    if (!wheelRef.current) return;

    if (!unclaimedSpins || unclaimedSpins.length === 0) {
      alert('Keine ungenutzten Spins vorhanden');
      return;
    }

    setSpinning(true);

    const spinId = unclaimedSpins[0].id;
    const res = await spinWheel(spinId);

    if (isApiError(res)) {
      console.error(res);
      setSpinning(false);
      return;
    }

    let activeWedge: Shape<ShapeConfig> | null = null;
    let previousColorStops: (string | number)[];

    const targetWedgeIndex = rewards.findIndex((reward) => reward.id === res.id);

    // Remove this line if your slices (sectors)
    // are painted anti-clockwise
    const index = rewards.length - targetWedgeIndex;

    // Absolute current angle (without turns)
    const angAbs = mod(ang, TAU);

    // Absolute new angle
    let angNew = arc * index;
    // (backtrack a bit to not end on the exact edge)
    angNew -= rand(0, arc);
    // Fix negative angles
    angNew = mod(angNew, TAU);

    // Get the angle difference
    const angDiff = mod(angNew - angAbs, TAU);

    // Add N full revolutions
    const rev = TAU * Math.floor(rand(8, 10));

    const newAng = ang + angDiff + rev;
    setAng(newAng);

    const tween = new Tween({
      node: wheelRef.current,
      duration: (rand(2000, 3000) * rev * 0.1) / 1000,
      rotation: newAng + angOffset,
      // easing: cubicBezier(0.2, 0, 0.1, 1),
      // easing: cubicBezier(0.5, 0, 0, 1),
      // easing: cubicBezier(0.3, 0.1, 0, 1),
      // easing: cubicBezier(0, 1, 0.3, 0.1), // falsch rum???
      // easing: cubicBezier(0.3, 0.1, 0.7, 1),
      // easing: cubicBezier(0.3, 1),
      // easing: Easings.StrongEaseOut,
      easing: Easings.EaseOut,
      onUpdate: () => {
        const stage = stageRef.current;
        const pointer = pointerRef.current;

        if (!stage || !pointer) return;

        // activate / deactivate wedges based on point intersection
        const shape = stage.getIntersection({
          x: stage.width() / 2,
          y: 100,
        });

        if (shape) {
          if (shape && (!activeWedge || shape._id !== activeWedge._id)) {
            pointer.y(radius / 7 + 2);

            new Tween({
              node: pointer,
              duration: 0.3,
              y: radius / 7 + 13,
              easing: Easings.ElasticEaseOut,
            }).play();

            const sound = new Howl({
              src: 'assets/sounds/wheel-of-fortune-tick.mp3',
              volume: 0.1,
            });

            sound.play();

            if (activeWedge && previousColorStops) {
              // activeWedge.fillRadialGradientColorStops(previousColorStops);
              // activeWedge.fillPriority('radial-gradient');
            }

            // previousColorStops = shape.fillRadialGradientColorStops();
            // shape.fillRadialGradientColorStops([0, '#C8FFFF', 1, '#64E9F8']);
            // shape.fillPriority('fill');
            activeWedge = shape;
          }
        }
      },
      onFinish: async () => {
        setSpinning(false);

        const sound = new Howl({
          src: 'assets/sounds/wheel-of-fortune-win.mp3',
          volume: 0.5,
        });

        sound.play();

        await wait(1000);

        onSpinningFinished(spinId, res);
      },
    });

    tween.play();
  }, [unclaimedSpins, ang, rewards, onSpinningFinished, spinning]);

  if (width === 0 || height === 0) {
    return <></>;
  }

  const adjustedHeight = height + 15;
  const radius = Math.min(height, width) / 2 - 8;
  const sliceWidth = sliceSize(rewards.length, radius).y * 0.5;

  return (
    <Stage ref={stageRef} width={width} height={adjustedHeight}>
      <Layer ref={layerRef}>
        <Group ref={wheelRef} x={width / 2} y={radius + 25} rotation={-Math.PI / 2}>
          {rewards.map((reward, i) => (
            <WheelWedge
              key={i}
              index={i}
              reward={reward}
              total={rewards.length}
              radius={radius}
              sliceWidth={sliceWidth}
            />
          ))}
        </Group>
        <Wedge
          ref={pointerRef}
          fillRadialGradientStartPoint={{ x: 0, y: 0 }}
          fillRadialGradientStartRadius={0}
          fillRadialGradientEndPoint={{ x: 0, y: 0 }}
          fillRadialGradientEndRadius={30}
          fillRadialGradientColorStops={[0, '#FCCD54', 1, '#FCCD54']}
          stroke={'#FFF'}
          strokeWidth={2}
          lineJoin={'round'}
          angle={1}
          radius={radius / 7}
          x={width / 2}
          y={radius / 7 + 13}
          rotation={-90}
          shadowColor={'black'}
          shadowOffsetX={3}
          shadowOffsetY={3}
          shadowBlur={2}
          shadowOpacity={0.5}
        ></Wedge>
        <Circle
          x={width / 2}
          y={radius + 25}
          radius={radius / 7}
          fill={'#fff'}
          shadowColor={'#000'}
          shadowBlur={10}
          shadowOpacity={0.5}
          onClick={handleClick}
          onTap={handleClick}
        ></Circle>
      </Layer>
    </Stage>
  );
};

export default WheelOfFortune;
