import React from 'react';

import { ClaimedSpinPrize, isPrintPrize } from '../../../api/interfaces/wheel-spins';

interface SizeChooserProps {
  selectedColor?: {
    id: number;
    name: string;
    hex: string;
    sizes: {
      id: number;
      name: string;
      available: boolean;
    }[];
  };
  selectedSize?: number;
  onSizeChange: (size: number) => void;
}

const SizeChooser: React.FC<SizeChooserProps> = ({ selectedColor, selectedSize, onSizeChange }) => {
  if (!selectedColor) {
    return <></>;
  }

  return (
    <div className='size-chooser'>
      <span>Größe{selectedColor.sizes.length > 1 ? ' wählen' : ''}:&nbsp;</span>
      <div className='sizes'>
        {selectedColor.sizes.map((size, index, sizes) => (
          <label key={size.id} className='size-button' title={size.name}>
            <input
              name='size'
              type='radio'
              checked={
                selectedSize === undefined || !sizes.find((s) => s.id === selectedSize)
                  ? sizes.length === 1
                  : size.id === selectedSize
              }
              onChange={() => onSizeChange(size.id)}
            />
            {size.name}
          </label>
        ))}
      </div>
    </div>
  );
};

interface ColorSizeChooserProps {
  prize: ClaimedSpinPrize;
  selectedColor?: number;
  selectedSize?: number;
  onColorChange: (color: number) => void;
  onSizeChange: (size: number) => void;
}

const ColorSizeChooser: React.FC<ColorSizeChooserProps> = ({
  prize,
  selectedColor,
  selectedSize,
  onColorChange,
  onSizeChange,
}) => {
  if (!isPrintPrize(prize) || !prize.info?.colors) {
    return <></>;
  }

  const selectedColorObject = prize.info.colors.find((c) => c.id === selectedColor);
  const color = prize.info.colors.length === 1 ? prize.info.colors[0] : selectedColorObject;

  return (
    <div className='size-color-chooser mb-4'>
      {prize.info.colors && (
        <div className='color-chooser'>
          <span>Farbe{prize.info.colors.length > 1 ? ' wählen' : ''}:&nbsp;</span>
          <div className='colors'>
            {prize.info.colors.map((color, index, colors) => (
              <label key={color.id} className='color-button' title={color.name}>
                <input
                  name='color'
                  type='radio'
                  checked={
                    selectedColor === undefined || !colors.find((c) => c.id === selectedColor)
                      ? index === 0
                      : color.id === selectedColor
                  }
                  onChange={() => onColorChange(color.id)}
                />
                <div className='color-indicator' style={{ backgroundColor: color.hex }}></div>
              </label>
            ))}
          </div>
        </div>
      )}
      <SizeChooser selectedColor={color} selectedSize={selectedSize} onSizeChange={onSizeChange} />
    </div>
  );
};

export default ColorSizeChooser;
