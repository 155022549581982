import { IonSpinner, useIonToast, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { getAccountInfo } from '../../api/account/account';
import { AccountInfo } from '../../api/interfaces/account-info';
import {
  ClaimedSpin,
  ClaimedSpinPrize,
  isPrintPrize,
  isRedeemedSpinPrize,
  RedeemedSpin,
  RedeemedSpinPrize,
  RedeemedSpinPrizeDiscount,
  WheelSpinPrizeOption,
  WheelSpins,
} from '../../api/interfaces/wheel-spins';
import { getWheelSpinPrizes, getWheelSpins, redeemWheelSpin } from '../../api/wheel-spins';
import B1Button from '../../components/buttons/B1Button';
import B1Modal from '../../components/modal/B1Modal';
import ChangeAddressModal from '../../components/modal/change-address-modal/ChangeAddressModal';
import ReferralModal from '../../components/modal/referral-modal/ReferralModal';
import { isApiError } from '../../utils/api-util';
import { SHORT_DATE_FORMAT } from '../../utils/date-util';
import Layout from '../Layout';
import ColorSizeChooser from './components/ColorSizeChooser';
import PreviousWinCard from './components/PreviousWinCard';
import WheelOfFortune from './components/WheelOfFortune';

import './Gluecksrad.scss';

const Gluecksrad: React.FC = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [presentToast] = useIonToast();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [wheelSpins, setWheelSpins] = useState<WheelSpins>();
  const [wheelSpinPrizes, setWheelSpinPrizes] = useState<WheelSpinPrizeOption[]>([]);
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const [previousWinsModalOpen, setPreviousWinsModalOpen] = useState(false);
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);
  const [changeAddressModalOpen, setChangeAddressModalOpen] = useState(false);

  const [selectedSpinId, setSelectedSpinId] = useState<number>();
  const [selectedPrize, setSelectedPrize] = useState<ClaimedSpinPrize | RedeemedSpinPrize>();
  const [selectedSize, setSelectedSize] = useState<number>();
  const [selectedColor, setSelectedColor] = useState<number>();

  const [loading, setLoading] = useState(false);

  useIonViewDidEnter(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
        setHeight(containerRef.current.offsetHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useIonViewWillEnter(() => {
    getAccountInfo().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setAccountInfo(res);
    });

    getWheelSpins().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setWheelSpins(res);
    });

    getWheelSpinPrizes().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setWheelSpinPrizes(res);
    });
  }, []);

  const unclaimedValidSpins =
    wheelSpins?.unclaimedSpins.filter((spin) => {
      return DateTime.fromISO(spin.valid_until) > DateTime.now();
    }) ?? [];

  const handleClaimedSpin = (spinId: number, result: ClaimedSpinPrize) => {
    setSelectedSpinId(spinId);
    setSelectedPrize(result);
    setSelectedSize(undefined);
    setSelectedColor(undefined);

    setRedeemModalOpen(true);

    getWheelSpins().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setWheelSpins(res);
    });
  };

  const openRedeemModal = (spin: ClaimedSpin | RedeemedSpin) => {
    setSelectedSpinId(spin.id);
    setSelectedPrize(spin.prize);
    setSelectedSize(undefined);
    setSelectedColor(undefined);

    setPreviousWinsModalOpen(false);
    setRedeemModalOpen(true);
  };

  const checkDataToRedeemSpin = () => {
    if (selectedSpinId === undefined || !selectedPrize) {
      console.error('No spin selected to redeem');
      return;
    }

    if (isRedeemedSpinPrize(selectedPrize)) {
      console.error('Spin already redeemed');
      return;
    }

    if (isPrintPrize(selectedPrize)) {
      const colors = selectedPrize.info?.colors;

      if (colors) {
        let tempColor;
        let tempSize;

        if (colors.length === 1) {
          tempColor = colors[0];
        } else {
          if (selectedColor !== undefined) {
            tempColor = colors.find((c) => c.id === selectedColor);
          }
        }

        if (!tempColor) {
          presentToast({
            message: 'Bitte wähle eine Farbe aus.',
            duration: 3000,
            position: 'bottom',
            color: 'warning',
          });
          return;
        }

        if (tempColor.sizes.length === 1) {
          tempSize = tempColor.sizes[0];
        } else {
          if (selectedSize !== undefined) {
            tempSize = tempColor.sizes.find((s) => s.id === selectedSize);
          }
        }

        if (!tempSize) {
          presentToast({
            message: 'Bitte wähle eine Größe aus.',
            duration: 3000,
            position: 'bottom',
            color: 'warning',
          });
          return;
        }

        setSelectedColor(tempColor.id);
        setSelectedSize(tempSize.id);
      }

      setChangeAddressModalOpen(true);
      return;
    }

    redeemSpin();
  };

  const handleAddressChanged = () => {
    setChangeAddressModalOpen(false);
    setRedeemModalOpen(true);
    redeemSpin();
  };

  const redeemSpin = () => {
    if (selectedSpinId === undefined) {
      console.error('No spin selected to redeem');
      return;
    }

    setLoading(true);

    redeemWheelSpin(selectedSpinId, selectedSize, selectedColor).then((res) => {
      setLoading(false);

      if (isApiError(res)) {
        console.error('Failed to redeem spin:', res);
        presentToast({
          message:
            'Leider ist beim beim Einlösen Deines Gewinns ein Fehler aufgetreten.\n' +
            'Bitte versuche es später erneut oder melde Dich bei uns.',
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
        return;
      }

      presentToast({
        message: 'Dein Gewinn wurde erfolgreich eingelöst!',
        duration: 5000,
        position: 'bottom',
        color: 'success',
      });

      getWheelSpins().then((res) => {
        if (isApiError(res)) {
          console.error(res);
          return;
        }

        setWheelSpins(res);

        const redeemed = res.redeemedSpins.find((spin) => spin.id === selectedSpinId);

        if (redeemed) {
          setSelectedPrize(redeemed.prize);
        }
      });
    });
  };

  const formatDiscount = (prize: RedeemedSpinPrizeDiscount) => {
    switch (prize.info?.discount_type) {
      case 'percent':
        return `${Math.round(Number(prize.info.discount))} %`;
      case 'fixed_cart':
      case 'fixed_product':
        return new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(prize.info.discount));
      default:
        return '';
    }
  };

  return (
    <Layout>
      <h1>🤩 Teste dein Glück</h1>
      <div className='share-container'>
        <div className='share-text'>
          <span>
            Dein Freund erhält <strong>15 EUR</strong> Rabatt und für jeden geworbenen Freund darfst
            du das Glücksrad einmal drehen.&nbsp;
          </span>
          <div className='share-code'>
            <span className='share-code-label'>Dein Code:&nbsp;</span>
            <span className='share-code'>{accountInfo?.user.kwk_coupon}</span>
          </div>
        </div>
        <B1Button className='share-button' click={() => setReferralModalOpen(true)}>
          Link teilen
        </B1Button>
      </div>
      <div className='wheel-wrapper'>
        <div ref={containerRef} className='wheel-container'>
          <WheelOfFortune
            width={width}
            height={height}
            rewards={wheelSpinPrizes}
            unclaimedSpins={unclaimedValidSpins}
            onSpinningFinished={handleClaimedSpin}
          />
        </div>
        <div className='wheel-info'>
          <h5 className='mb-3'>Teste dein Glück</h5>
          <div className='remaining-spins'>
            <span>Verbleibende Versuche</span>
            <span className='spins'>{unclaimedValidSpins.length ?? 0}</span>
          </div>
          <div className='invited-friends'>
            <span>Geworbene Freunde</span>
            <span className='friends'>
              {(wheelSpins?.unclaimedSpins.length ?? 0) +
                (wheelSpins?.claimedSpins.length ?? 0) +
                (wheelSpins?.redeemedSpins.length ?? 0)}
            </span>
          </div>

          <B1Button
            className='button-reverse button-block mt-5'
            click={() => setReferralModalOpen(true)}
          >
            Freunde werben
          </B1Button>

          {((wheelSpins?.claimedSpins.length ?? 0) > 0 ||
            (wheelSpins?.redeemedSpins.length ?? 0) > 0) && (
            <B1Button
              className='button-obscure button-block mt-4 mb-5'
              click={() => setPreviousWinsModalOpen(true)}
            >
              Bisherige Gewinne
            </B1Button>
          )}
        </div>
      </div>

      <ReferralModal
        open={referralModalOpen}
        setOpen={setReferralModalOpen}
        accountInfo={accountInfo}
      />

      <B1Modal
        className='previous-wins-modal'
        open={previousWinsModalOpen}
        onModalClose={() => setPreviousWinsModalOpen(false)}
      >
        <h2 className='mb-4'>Bisherige Gewinne</h2>

        <div className='previous-wins'>
          {wheelSpins?.claimedSpins.map((spin) => (
            <PreviousWinCard key={spin.id} spin={spin} onButtonClick={openRedeemModal} />
          ))}

          {(wheelSpins?.redeemedSpins.length ?? 0) > 0 && (
            <>
              <hr className='mt-5' />
              <h5 className='mt-3 mb-3'>Eingelöste Gewinne</h5>

              {wheelSpins?.redeemedSpins.map((spin) => (
                <PreviousWinCard key={spin.id} spin={spin} onButtonClick={openRedeemModal} />
              ))}
            </>
          )}
        </div>
      </B1Modal>

      <B1Modal
        className='redeem-modal modal-white'
        open={redeemModalOpen}
        onModalClose={() => setRedeemModalOpen(false)}
        enableAutoHeight
      >
        <h1 className='mb-2'>🎉</h1>
        <h2 className='mb-4'>Dein Gewinn: {selectedPrize?.title}</h2>

        {selectedPrize?.picture && (
          <img src={selectedPrize.picture} className='mb-4 spin-prize-picture' />
        )}

        {selectedPrize?.description && <p className='mb-4'>{selectedPrize?.description}</p>}

        {selectedPrize && !isRedeemedSpinPrize(selectedPrize) ? (
          <>
            <ColorSizeChooser
              prize={selectedPrize}
              selectedColor={selectedColor}
              selectedSize={selectedSize}
              onColorChange={setSelectedColor}
              onSizeChange={setSelectedSize}
            />

            <B1Button click={() => checkDataToRedeemSpin()} disabled={loading}>
              {loading && <IonSpinner />}
              Jetzt einlösen
            </B1Button>
          </>
        ) : (
          selectedPrize?.info &&
          (selectedPrize?.type === 'course' ? (
            <p>
              {selectedPrize.info.length === 1 ? 'Dein Kurs' : 'Deine Kurse'}:{' '}
              {selectedPrize.info
                .map((i) => {
                  let text = i.identifier;

                  if (i.valid_until) {
                    text += ` (gültig bis ${DateTime.fromISO(i.valid_until).toLocaleString(SHORT_DATE_FORMAT)})`;
                  }

                  return text;
                })
                .join(', ')}
            </p>
          ) : selectedPrize?.type === 'print' ? (
            <>
              <p>Dein Auftragsstatus: {selectedPrize.info.status}</p>
              {selectedPrize.info.shipping_link && (
                <B1Button href={selectedPrize.info.shipping_link} external className='mt-3'>
                  Versandstatus ansehen
                </B1Button>
              )}
            </>
          ) : selectedPrize?.type === 'charter_code' ? (
            <>
              <p>
                Dein Rabattgutschein: <strong>{selectedPrize.info.code}</strong>
              </p>
              {selectedPrize.info.valid_until && (
                <p>
                  Einlösbar bis:{' '}
                  {DateTime.fromISO(selectedPrize.info.valid_until).toLocaleString(
                    SHORT_DATE_FORMAT
                  )}
                </p>
              )}
              {selectedPrize.info.link && (
                <p className='mt-4'>
                  <B1Button href={selectedPrize.info.link} external>
                    Hier einlösen
                  </B1Button>
                </p>
              )}
            </>
          ) : selectedPrize?.type === 'discount' ? (
            <>
              <p>
                Dein Rabatt-Gutschein von {formatDiscount(selectedPrize)}:{' '}
                <strong>{selectedPrize.info.code.toUpperCase()}</strong>
              </p>
              {selectedPrize.info.valid_until && (
                <p>
                  Einlösbar bis:{' '}
                  {DateTime.fromISO(selectedPrize.info.valid_until).toLocaleString(
                    SHORT_DATE_FORMAT
                  )}
                </p>
              )}
              <p className='mt-4'>
                <B1Button href={selectedPrize.info.link} external>
                  Hier einlösen
                </B1Button>
              </p>
            </>
          ) : (
            <></>
          ))
        )}
      </B1Modal>

      <ChangeAddressModal
        open={changeAddressModalOpen}
        setOpen={setChangeAddressModalOpen}
        onAddressChanged={handleAddressChanged}
      />
    </Layout>
  );
};

export default Gluecksrad;
