type WheelSpinPrizeType = 'charter_code' | 'discount' | 'course' | 'print' | 'manual';

export interface WheelSpinPrize {
  id: number;
  title: string;
  description: string | null;
  picture: string | undefined;
}

// TODO: Sinnvolleren Namen finden?
export interface WheelSpinPrizeOption extends WheelSpinPrize {
  color: string;
}

export interface ClaimedSpinPrizeGeneric extends WheelSpinPrize {
  type: Exclude<WheelSpinPrizeType, 'print'>;
}

export interface ClaimedSpinPrizePrint extends WheelSpinPrize {
  type: 'print';
  info:
    | {
        colors:
          | {
              id: number;
              name: string;
              hex: string;
              sizes: {
                id: number;
                name: string;
                available: boolean;
              }[];
            }[]
          | undefined;
      }
    | undefined;
}

/**
 * Type for claimed prizes as well as wheel spin result
 */
export type ClaimedSpinPrize = ClaimedSpinPrizeGeneric | ClaimedSpinPrizePrint;

interface RedeemedSpinPrizeCourse extends WheelSpinPrize {
  type: 'course';
  info:
    | {
        identifier: string;
        valid_until: string | undefined; // Date
      }[]
    | undefined;
}

interface RedeemedSpinPrizePrint extends WheelSpinPrize {
  type: 'print';
  info:
    | {
        status: string;
        shipping_link: string | undefined;
      }
    | undefined;
}

interface RedeemedSpinPrizeCharterCode extends WheelSpinPrize {
  type: 'charter_code';
  info:
    | {
        code: string | undefined;
        valid_until: string | null | undefined; // Date
        link: string | null;
      }
    | undefined;
}

export interface RedeemedSpinPrizeDiscount extends WheelSpinPrize {
  type: 'discount';
  info:
    | {
        code: string;
        valid_until: string | undefined;
        discount: string | undefined;
        discount_type: string | undefined;
        link: string;
      }
    | undefined;
}

interface RedeemedSpinPrizeManual extends WheelSpinPrize {
  type: 'manual';
  info: undefined;
}

/*
 * Create a union of the possible types of RedeemedSpinPrize
 */
export type RedeemedSpinPrize =
  | RedeemedSpinPrizeCourse
  | RedeemedSpinPrizePrint
  | RedeemedSpinPrizeCharterCode
  | RedeemedSpinPrizeDiscount
  | RedeemedSpinPrizeManual;

export interface UnclaimedSpin {
  id: number;
  valid_until: string;
}

export interface ClaimedSpin {
  id: number;
  claimed_at: string | null; // Date
  prize: ClaimedSpinPrize | undefined;
}

export interface RedeemedSpin {
  id: number;
  redeemed_at: string | null; // Date
  prize: RedeemedSpinPrize | undefined;
}

export interface WheelSpins {
  redeemedSpins: RedeemedSpin[];
  claimedSpins: ClaimedSpin[];
  unclaimedSpins: UnclaimedSpin[];
}

export const isRedeemedSpin = (spin: ClaimedSpin | RedeemedSpin): spin is RedeemedSpin => {
  return (spin as RedeemedSpin).redeemed_at !== undefined;
};

export const isRedeemedSpinPrize = (
  prize: ClaimedSpinPrize | RedeemedSpinPrize
): prize is RedeemedSpinPrize => {
  if ((prize as ClaimedSpinPrizePrint).info?.colors !== undefined) {
    return false;
  }
  return (prize as RedeemedSpinPrize).info !== undefined;
};

export const isPrintPrize = (prize: ClaimedSpinPrize): prize is ClaimedSpinPrizePrint => {
  return (prize as ClaimedSpinPrizePrint).info?.colors !== undefined;
};
